import { ConversationalForm } from "conversational-form";
import { countries } from 'countries-list';

const dataFromPeopleData = {
  phone: "",
  country: "",
  company: "",
  companysize: "",
  title: "",
};

// Define preferred and all countries with abbreviations
const preferredCountries = [
  { abbr: "USA", name: "United States" },
  { abbr: "CA", name: "Canada" },
  { abbr: "GB UK", name: "United Kingdom" },
  { abbr: "AU", name: "Australia" },
  { abbr: "SG", name: "Singapore" },
  { abbr: "NZ", name: "New Zealand" },
  { abbr: "DE", name: "Germany"},
  {abbr: "IE", name: "Ireland"},
  {abbr: "MR", name: "Mauritius"},
];

// Get the list of all countries with abbreviations and names
const allCountries = Object.entries(countries).map(([abbr, countryData]) => ({
  abbr: abbr,
  name: countryData.name
}));


// Filter and combine preferred and other countries
const uniqueOtherCountries = allCountries.filter(
  country => !preferredCountries.some(preferred => preferred.name === country.name)
);
const combinedCountries = [...preferredCountries, ...uniqueOtherCountries];
/** Handler for submitted answers */

function answerSubmittedHandler(dto, success, error) {
  const conversation = dto.input.cfReference;
  const formData = conversation.getFormData(true);
  const stepNo = conversation.flowManager.step;

  // After step 4, add required fields if they are empty
  if (stepNo === 4) {
      if (!dataFromPeopleData.phone) {
          window.ConversationalForm.addTags([
              { tag: "input", name: "phoneNumber", "cf-questions": "Could you tell me your phone number?" }
          ], true, 0);
      }
      if (!dataFromPeopleData.title) {
          window.ConversationalForm.addTags([
              { tag: "input", name: "title", "cf-questions": "Could you tell me your job title?" }
          ], true, 1);
      }
      if (!dataFromPeopleData.company) {
          window.ConversationalForm.addTags([
              { tag: "input", name: "company", "cf-questions": "Could you tell me your company name?" }
          ], true, 1);
      }
      if (!dataFromPeopleData.companysize) {
          window.ConversationalForm.addTags([
              { tag: "input", name: "companysize", "cf-questions": "Could you tell me your company employee size?" }
          ], true, 1);
      }

      // Add country dropdown with preferred countries at the top, using abbreviations
      if (!dataFromPeopleData.country) {
        window.ConversationalForm.addTags([
            {
                tag: "select",
                name: "country",
                "cf-questions": "Could you tell me your country?",
                "cf-error": "Invalid country name",
                "children": [
                    {
                        tag: "option",
                        value: "",
                        "cf-label": "Please Select",
                        disabled: true,
                        selected: true,
                        style: "display: none;" // Hide the placeholder option
                    },
                    ...combinedCountries.map(country => ({
                        tag: "option",
                        value: country.name,        // Use full name as the value
                        "cf-label": `${country.name} <span style="display:none">(${country.abbr})</span>`    // Show full name and abbreviation
                    }))
                ]
            }
        ], true, 1);
    }
  }

  // Country validation to ensure only valid countries are accepted
// Country validation to ensure only valid countries are accepted
if (dto.tag.name === "country") {
  // If countryValue is an array, use the first element; otherwise, use it as-is
  let countryValue = dto.tag.value;
  if (Array.isArray(countryValue)) {
      countryValue = countryValue[0];  // Get the first item from the array
  }

  console.log("Selected country name (after array check):", countryValue);  // Debugging

  // Ensure countryValue is a string after array extraction
  if (typeof countryValue !== "string") {
      console.error("Unexpected type for countryValue after array check:", typeof countryValue, countryValue);
      return error("Invalid country selected.");
  }

  // Check if the selected country name is valid (case-insensitive)
  const isValidCountry = combinedCountries.some(
      country => country.name.toLowerCase() === countryValue.toLowerCase()
  );

  if (!isValidCountry) {
      conversation.addRobotChatResponse("Please select a valid country from the list.");
      return error("Invalid country selected.");
  }
}

  // Custom email validation
  if (dto.tag.name === "email") {
      const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
      if (dto.text.trim() === "") {
          conversation.addRobotChatResponse("Please provide your email address.");
          return error("Email is required.");
      } else if (!emailPattern.test(dto.text)) {
          conversation.addRobotChatResponse("The email you entered is invalid. Please enter a valid email address.");
          return error("Invalid email format.");
      } else {
          (async () => {
              try {
                  const peopledata = await PeopleData(dto.text.trim());

                  // Populate data based on API response
                  if (peopledata) {
                      dataFromPeopleData.phone = "6474729334"; // Placeholder for flow continuation
                      dataFromPeopleData.country = peopledata["countries"][0];
                      dataFromPeopleData.company = peopledata["job_company_name"];
                      dataFromPeopleData.title = peopledata["job_title"];
                      dataFromPeopleData.companysize = peopledata["job_company_size"];
                  }
              } catch (error) {
                  console.error("An error occurred during enrichment:", error);
              }
          })();
      }
  }

  // Phone number validation
  if (dto.tag.name === "phoneNumber") {
      const phonePattern = /^\d{10}$/; // 10-digit phone number format
      if (dto.text.trim() === "") {
          conversation.addRobotChatResponse("Please provide your phone number.");
          return error("Phone number is required.");
      } else if (!phonePattern.test(dto.text)) {
          conversation.addRobotChatResponse("Please provide a valid 10-digit phone number.");
          return error("Invalid phone number format.");
      }
  }

  // Log form data for debugging
  console.log("Form data so far:", conversation.getFormData(true));
  success();
}

// Event listener for filtering countries as the user types
document.addEventListener("input", function (e) {
  if (e.target && e.target.name === "country") {
    filterCountries(e.target.value);
  }
});
function conversationFinishedHandler(conversation) {
  const formData = conversation.getFormData(true);

  // Ensure that all required questions are asked and that data is collected
  if (!formData.phoneNumber) {
    formData.phoneNumber = dataFromPeopleData.phone;
  }
  if (!formData.title) {
    formData.title = dataFromPeopleData.title;
  }
  if (!formData.company) {
    formData.company = dataFromPeopleData.company;
  }
  if (!formData.companysize) {
    formData.companysize = dataFromPeopleData.companysize;
  }
  if (!formData.country) {
    formData.country = dataFromPeopleData.country;
  }

  // Check if the user selected "no" for submitting the information
  // Adjust the condition to check if "info-share" is an array and its first element is "no"
  if (formData["info-share"] && formData["info-share"][0] === "no") {
    conversation.addRobotChatResponse("You have chosen not to submit your information.");
    return; // Stop further processing, no thank you message or form data appending
  }

  // If the user selects "Yes", proceed with showing the thank you message and appending data
  if (formData["info-share"] && formData["info-share"][0] === "yes") {
    // Log the collected form data for debugging
    console.log("Form data: ", formData);

    // Create and append form data to a div with id "yourData"
    const yourDataDiv = document.getElementById("yourData");
    if (yourDataDiv) {
      yourDataDiv.innerHTML = ""; // Clear any existing content

      const ul = document.createElement("ul");
      ul.classList.add('list-disc');

      // Iterate over formData and append to the list
      for (const key in formData) {
        if (formData.hasOwnProperty(key)) {
          const li = document.createElement("li");
          li.textContent = `${key}: ${formData[key]}`;
          ul.appendChild(li);
        }
      }
      // Show the data div (hidden by default)
      yourDataDiv.classList.remove('hidden');

      yourDataDiv.appendChild(ul);
    }


    // Show a thank you message and the form data in the chat response
    conversation.addRobotChatResponse("Thank you for submitting the form!");
  }
}


document.addEventListener("DOMContentLoaded", function () {
  // Initialize Conversational Form
  const cf = new ConversationalForm({
    formEl: document.getElementById("sample-conversation"),
    flowStepCallback: answerSubmittedHandler,  // Ensures proper flow of questions
    submitCallback: conversationFinishedHandler,  // Final callback
    theme: "blue",
    context: document.getElementById("sample-conversation"),
    showProgressBar: true,


  });

  // Function to update background images for user and robot thumbs
  const updateThumbImages = () => {
    const userThumbs = document.querySelectorAll("cf-chat-response.user thumb");
    const robotThumbs = document.querySelectorAll("cf-chat-response.robot thumb");

    // Update all user avatars for each prompt
    userThumbs.forEach(userImageElement => {
      userImageElement.style.setProperty('background-image', "url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0NDggNTEyIj48cGF0aCBzdHlsZT0iZmlsbDojMzA2N0RCIiBkPSJNMjI0IDI1NkExMjggMTI4IDAgMSAwIDIyNCAwYTEyOCAxMjggMCAxIDAgMCAyNTZ6bS00NS43IDQ4Qzc5LjggMzA0IDAgMzgzLjggMCA0ODIuM0MwIDQ5OC43IDEzLjMgNTEyIDI5LjcgNTEybDM4OC42IDBjMTYuNCAwIDI5LjctMTMuMyAyOS43LTI5LjdDNDQ4IDM4My44IDM2OC4yIDMwNCAyNjkuNyAzMDRsLTkxLjQgMHoiLz48L3N2Zz4=')", 'important');
    });

    // Update all robot avatars for each prompt
    robotThumbs.forEach(robotImageElement => {
      robotImageElement.style.setProperty('background-image', "url('https://driftt.imgix.net/https%3A%2F%2Fdriftt.imgix.net%2Fhttps%253A%252F%252Fs3.us-east-1.amazonaws.com%252Fcustomer-api-avatars-prod%252F1283461%252F38add9576d2d79808f6e2f98299e437ctzebgcrrhv75%3Ffit%3Dmax%26fm%3Dpng%26h%3D200%26w%3D200%26s%3D9dc2d6d845d2321a0d7fbf5d0deb3774?fit=max&fm=png&h=200&w=200&s=5066c6bb1f602024772a7b95d1bda67c')", 'important');
    });
  };

  // Create a MutationObserver to detect changes in cf-chat-response elements
  const observer = new MutationObserver(function (mutationsList) {
    for (let mutation of mutationsList) {
      if (mutation.type === 'childList') {
        updateThumbImages();  // Update images when new messages are added
      }
    }
  });

  // Start observing the parent chat container for changes
  const chatContainer = document.querySelector('cf-chat');
  if (chatContainer) {
    observer.observe(chatContainer, {
      childList: true,  // Watch for additions/removals of direct children
      subtree: true     // Watch for all descendant changes
    });
  }

  // Call updateThumbImages immediately after initialization to update any existing messages
  updateThumbImages();
});


// Hide defaultForm by default if it exists
const defaultForm = document.getElementById("defaultForm");
if (defaultForm) {
  defaultForm.classList.add("hidden");
} else {
  console.warn("defaultForm element not found.");
}

// Add event listener to the toggle button if it exists
const formToggle = document.getElementById("formToggle");
const convForm = document.getElementById("convForm"); // Assuming this exists in your DOM
if (formToggle && convForm) {
  formToggle.addEventListener("click", function () {
    // Toggle between convForm and defaultForm
    if (convForm.classList.contains("hidden")) {
      convForm.classList.remove("hidden");
      defaultForm.classList.add("hidden");
      formToggle.textContent = "Show Default Form";
    } else {
      convForm.classList.add("hidden");
      defaultForm.classList.remove("hidden");
      formToggle.textContent = "Show Conv. Form";
    }
  });
} else {
  console.warn("formToggle or convForm element not found.");
}

// Ensure the tab controller exists before applying styles
window.addEventListener('load', function () {
  setTimeout(() => {
    const backgroundButton = document.querySelector('ceridian-tab-controller .bg-button-primary');

    if (backgroundButton) {
      backgroundButton.style.setProperty('--tw-translate-x', '8px');
      backgroundButton.style.width = '145px';
    } else {
      console.error("Background button not found in ceridian-tab-controller.");
    }
  }, 100); // Adjust the timeout duration if needed
});
